import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

export const useGlobalClick = () => {
    const counter = useRef<number>(0);
    const dispatch = useDispatch();

    React.useEffect(() => {
        const handleClick = () => {
            if (localStorage.getItem('token')) {
                counter.current = counter.current + 1; // store previous value in prevCount
                console.log(`Global click: ${counter.current}`);

                dispatch({ type: 'set', isSlowMode: false });
            }
        };

        document.addEventListener('click', handleClick);

        return () => {
            document.removeEventListener('click', handleClick);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [counter]);

    return { counter };
};
