import { gql } from '@apollo/client';
import { MODERATED_PROFILE_FRAGMENT, PROFILE_FLAGS_FRAGMENT, PROFILE_FRGAMENT, PROFILE_INTERESTS_FRAGMENT, USER_FRAGMENT } from './fragments';

export * from './profile';
export * from './billing';
export * from './auth';
export * from './user';
export * from './complains';
export * from './comment';
export * from './interests';
export * from './avatar';
export * from './defaultText';
export * from './fragments';
export * from './legacy';

export const PROFILE_QUERY_COUNT = gql`
    query findManyProfileCount($where: ProfileWhereInput) {
        findManyProfileCount(where: $where)
    }
`;

export const PROFILE_QUERY_FEATURES = gql`
    ${USER_FRAGMENT}
    ${PROFILE_FRGAMENT}
    ${MODERATED_PROFILE_FRAGMENT}
    ${PROFILE_INTERESTS_FRAGMENT}
    ${PROFILE_FLAGS_FRAGMENT}
    query findManyProfileFeatures(
        $where: ProfileWhereInput
        $take: Int = 50
        $skip: Int = 0
        $withModeratedProfiles: Boolean = false
        $withConversationProfiles: Boolean = false
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withAvatar: Boolean = false
        $withTransactions: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $withPictures: Boolean = false
        $picturesTake: Int = 20
        $picturesSkip: Int = 0
    ) {
        findManyProfile(where: $where, take: $take, skip: $skip, orderBy: { createdAt: desc }) {
            ...ProfileFragment
            defaultText
            profileTextId
            ... @include(if: $withProfileFlags) {
                ...ProfileFlagsFragment
            }
            ... @include(if: $withAvatar) {
                avatar {
                    path
                    avatarId
                    name
                    gender
                    translations {
                        translation
                    }
                }
            }
            ... @include(if: $withModeratedProfiles) {
                moderatedProfile {
                    ...ModeratedProfileFragment
                }
            }
            ... @include(if: $withImages) {
                images(orderBy: { main: desc }) {
                    imageId
                    path
                    main
                    private
                    fsk18
                    projectName
                    verified
                }
            }
            ... @include(if: $withPictures) {
                pictures(take: $picturesTake, skip: $picturesSkip) {
                    pictureId
                    path(size: 100)
                }
            }
            ... @include(if: $withProfileInterests) {
                ...ProfileInterestsFragment
            }
            ... @include(if: $withConversationProfiles) {
                conversationProfiles(take: 5, orderBy: { updatedAt: desc }) {
                    messagesSend
                    send
                    pokesSend
                    giftsSend
                }
            }
            user {
                ...UserFragment
                ... @include(if: $withTransactions) {
                    transactionCount
                }
                ... @include(if: $withUserPremiumStatus) {
                    userPremiumStatus {
                        profilePremiumStatusId
                        status
                        validTo
                    }
                }
            }
            projectName
        }
    }
`;

export const PROFILE_QUERY = gql`
    ${USER_FRAGMENT}
    ${PROFILE_FRGAMENT}
    ${MODERATED_PROFILE_FRAGMENT}
    ${PROFILE_INTERESTS_FRAGMENT}
    ${PROFILE_FLAGS_FRAGMENT}
    query findManyProfile(
        $where: ProfileWhereInput
        $take: Int = 50
        $skip: Int = 0
        $withModeratedProfiles: Boolean = false
        $withConversationProfiles: Boolean = false
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $withAvatar: Boolean = false
        $withPictures: Boolean = false
        $picturesTake: Int = 20
        $picturesSkip: Int = 0
    ) {
        findManyProfile(where: $where, take: $take, skip: $skip, orderBy: { createdAt: desc }) {
            ...ProfileFragment
            ... @include(if: $withAvatar) {
                createdAt
            }
            ... @include(if: $withProfileFlags) {
                ...ProfileFlagsFragment
            }
            ... @include(if: $withModeratedProfiles) {
                moderatedProfile {
                    ...ModeratedProfileFragment
                }
            }
            ... @include(if: $withImages) {
                images(orderBy: { main: desc }) {
                    imageId
                    path
                    main
                    private
                    fsk18
                    projectName
                    verified
                }
            }
            ... @include(if: $withPictures) {
                pictures(take: $picturesTake, skip: $picturesSkip) {
                    pictureId
                    path(size: 100)
                }
            }
            ... @include(if: $withProfileInterests) {
                ...ProfileInterestsFragment
            }
            ... @include(if: $withConversationProfiles) {
                conversationProfiles(take: 5, orderBy: { updatedAt: desc }) {
                    messagesSend
                    send
                    pokesSend
                    giftsSend
                }
            }
            user {
                ...UserFragment
                ... @include(if: $withUserPremiumStatus) {
                    userPremiumStatus {
                        profilePremiumStatusId
                        status
                        validTo
                    }
                }
            }
            projectName
        }
    }
`;

export const PROFILE_QUERY_HAZELZ = gql`
    ${USER_FRAGMENT}
    ${PROFILE_FRGAMENT}
    ${MODERATED_PROFILE_FRAGMENT}
    ${PROFILE_INTERESTS_FRAGMENT}
    ${PROFILE_FLAGS_FRAGMENT}
    query findManyProfileHazelz(
        $where: ProfileWhereInput
        $take: Int = 50
        $skip: Int = 0
        $withModeratedProfiles: Boolean = false
        $withConversationProfiles: Boolean = false
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withAvatar: Boolean = false
        $withTransactions: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $withPictures: Boolean = false
        $picturesTake: Int = 20
        $picturesSkip: Int = 0
    ) {
        findManyProfile(where: $where, take: $take, skip: $skip, orderBy: { createdAt: desc }) {
            ...ProfileFragment
            ... @include(if: $withProfileFlags) {
                ...ProfileFlagsFragment
            }
            ... @include(if: $withAvatar) {
                avatar {
                    path
                    avatarId
                    name
                    gender
                    translations {
                        translation
                    }
                }
            }
            ... @include(if: $withModeratedProfiles) {
                moderatedProfile {
                    ...ModeratedProfileFragment
                }
            }
            ... @include(if: $withImages) {
                images(orderBy: { main: desc }) {
                    imageId
                    path
                    main
                    private
                    fsk18
                    projectName
                    verified
                }
            }
            ... @include(if: $withPictures) {
                pictures(take: $picturesTake, skip: $picturesSkip) {
                    pictureId
                    path(size: 100)
                }
            }
            ... @include(if: $withProfileInterests) {
                ...ProfileInterestsFragment
            }
            ... @include(if: $withConversationProfiles) {
                conversationProfiles(take: 5, orderBy: { updatedAt: desc }) {
                    messagesSend
                    send
                    pokesSend
                    giftsSend
                }
            }
            user {
                ...UserFragment
                ... @include(if: $withTransactions) {
                    transactionCount
                }
                ... @include(if: $withUserPremiumStatus) {
                    userPremiumStatus {
                        profilePremiumStatusId
                        status
                        validTo
                    }
                }
            }
            projectName
        }
    }
`;

export const USERS_ONLINE_QUERY = gql`
    query findOnlineUser($gte: DateTime, $withUsers: Boolean = false) {
        mods: findManyModerationUser(where: { chatStatus: { in: [Online, Break, Answer] } }, orderBy: { lastLogin: desc }) {
            moderationUserId
            name
            activeQueue {
                queueId
                type
            }
            teams {
                name
                moderationTeamId
            }
            agency {
                name
            }
            chatStatus
        }
        admins: findManyAdminUser(where: { online: { equals: true } }, orderBy: { createdAt: desc }) {
            adminUserId
            lastLogin
            name
            adminRoleName
            privileges {
                privilege
            }
        }
        ... @include(if: $withUsers) {
            users: findManyUser(where: { online: { equals: true }, lastActivity: { gte: $gte } }, orderBy: { createdAt: asc }) {
                userId
                username
                coins
                bonus
                client
                createdAt
                createdIP
                lastLogin
                email
                mobileNumber
                emailValidated
                mobileNumberValidated
                profileId
                role
                lastAction
                lastActivity
                profile {
                    profileName
                    gender
                    searches
                    birthDate
                    postcode
                    country
                }
            }
        }
    }
`;

export const USER_QUERY = gql`
    query findManyUser(
        $where: UserWhereInput
        $take: Int = 50
        $skip: Int = 0
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withTransactions: Boolean = false
        $withStatistics: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $orderBy: UserOrderByInput
    ) {
        findManyUser(where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
            userId
            legacyUserId
            email
            mobileNumber
            lastAction
            lastMessageReceived
            lastMessageSend
            role
            username
            updatedAt
            createdAt
            createdIP
            sendTransactionalEmail
            sendMarketingEmail
            sendSMS
            emailValidated
            emailValidatedAt
            mobileNumberValidated
            mobileNumberValidatedAt
            conditionAcceptedAt
            client
            clientVersion
            c4fId
            hasPayed
            verified
            online
            lastLogin
            domain
            registerDomain
            ... @include(if: $withUserPremiumStatus) {
                userPremiumStatus {
                    profilePremiumStatusId
                    userId
                    status
                    validTo
                    createdAt
                }
            }
            ... @include(if: $withTransactions) {
                transactionCount
            }
            coins
            bonus
            latitude
            longitude
            projectName
            profileId
            profile {
                profileId
                profileName
                gender
                searches
                country
                timezone
                postcode
                furtherInformation
                themeName
                adminText
                languageCode
                hidden
                hideLocation
                birthDate
                createdAt
                shownLocation
                flirtText
                premiumStatus
                ... @include(if: $withProfileFlags) {
                    profileFlags {
                        flag {
                            name
                        }
                        createdAt
                    }
                }
                ... @include(if: $withImages) {
                    images {
                        imageId
                        path
                        main
                        private
                        fsk18
                        projectName
                        verified
                    }
                }
                ... @include(if: $withProfileInterests) {
                    profileInterests {
                        interest {
                            name
                        }
                    }
                }
            }
            socials {
                providerName
                socialId
                providerId
            }
            ... @include(if: $withStatistics) {
                userStatistic {
                    ins
                    sentMessages
                    spentBonus
                    spentCoins
                    transactions
                }
            }
        }
    }
`;

export const USER_COUNT_QUERY = gql`
    query findManyUserCount($where: UserWhereInput) {
        findManyUserCount(where: $where)
    }
`;

export const MOD_QUERY = gql`
    query UsersFindManyModerationUser($where: ModerationUserWhereInput, $take: Int = 10, $skip: Int) {
        findManyModerationUser(where: $where, take: $take, skip: $skip, orderBy: { createdAt: desc }) {
            moderationUserId
            name
            role
            minCharAwr
            minCharMessage
            fixedConversation
            factor
            createdAt
            status
            lastLogin
            teams {
                moderationTeamId
                name
            }
            agency {
                agencyId
                name
            }
            role
            privileges {
                moderationPrivilege
            }
            languageCode
            projects {
                projectName
                moderationUserProjectId
            }
        }
    }
`;

export const MOD_COUNT_QUERY = gql`
    query findManyModerationUserCount($where: ModerationUserWhereInput) {
        findManyModerationUserCount(where: $where)
    }
`;

export const MOD_MUTATION_CREATE = gql`
    mutation createOneModerationUser($data: ModerationUserCreateInput!) {
        createOneModerationUser(data: $data) {
            name
            moderationUserId
        }
    }
`;

export const MOD_MUTATION_UPDATE = gql`
    mutation updateOneModerationUser($where: ModerationUserWhereUniqueInput!, $data: ModerationUserUpdateInput!) {
        updateOneModerationUser(where: $where, data: $data) {
            moderationUserId
            name
            role
            minCharAwr
            minCharMessage
            fixedConversation
            factor
            createdAt
            privileges {
                moderationPrivilege
            }
            teams {
                moderationTeamId
                name
            }
            agency {
                agencyId
                name
            }
            role
        }
    }
`;

export const ADMINUSER_QUERY = gql`
    query findManyAdminUser($take: Int = 100, $skip: Int = 0) {
        findManyAdminUser(take: $take, skip: $skip, orderBy: { createdAt: desc }) {
            adminUserId
            name
            status
            languageCode
            adminRoleName
            projects {
                name
            }
            privileges {
                privilege
            }
            agencyId
            adminUserTeam {
                moderationTeamId
            }
        }
    }
`;

export const ADMINUSER_MUTATION_CREATE = gql`
    mutation createOneAdminUser($data: AdminUserCreateInput!) {
        createOneAdminUser(data: $data) {
            name
            adminUserId
            status
        }
    }
`;
export const ADMINUSER_MUTATION_UPDATE = gql`
    mutation updateOneAdminUser($data: AdminUserUpdateInput!, $where: AdminUserWhereUniqueInput!) {
        updateOneAdminUser(data: $data, where: $where) {
            adminUserId
            name
            status
        }
    }
`;

export const ADMINROLE_MUTATION_CREATE = gql`
    mutation createOneAdminUserPrivilege($data: AdminUserPrivilegeCreateInput!) {
        createOneAdminUserPrivilege(data: $data) {
            adminUserPrivilegeId
        }
    }
`;

export const PICTURE_MUTATION_CREATE = gql`
    mutation uploadPicture($data: PictureCreateInput!, $where: ProfileWhereUniqueInput!) {
        uploadPicture(where: $where, data: $data) {
            pictureId
            path
            fsk18
            projectName
        }
    }
`;

export const PROFILEIMAGE_MUTATION_CREATE = gql`
    mutation createOneImage($data: ImageCreateInput!) {
        createOneImage(data: $data) {
            imageId
            main
            path
            private
            fsk18
            verified
            projectName
        }
    }
`;

export const PROFILEIMAGE_MUTATION_DELETE = gql`
    mutation deleteOneImage($where: ImageWhereUniqueInput!) {
        deleteOneImage(where: $where) {
            imageId
        }
    }
`;

export const PROFILEIMAGE_MUTATION_UPDATE = gql`
    mutation updateOneImage($where: ImageWhereUniqueInput!, $data: ImageUpdateInput!) {
        updateOneImage(where: $where, data: $data) {
            imageId
            main
            path
            private
            fsk18
            verified
            projectName
        }
    }
`;

export const PROFILEIMAGES_MUTATION_UPDATE = gql`
    mutation updateManyImage($data: [ImageUpdateManyInput!]!) {
        updateManyImage(data: $data) {
            count
        }
    }
`;

export const PROFILEIMAGE_QUERY = gql`
    query Images($where: ImageWhereInput, $orderBy: ImageOrderByInput, $cursor: ImageWhereUniqueInput, $skip: Int, $take: Int) {
        findManyImage(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
            imageId
            profile {
                profileName
                profileId
                images(where: { main: { equals: true } }) {
                    path
                }
                moderatedProfile {
                    moderatedProfileId
                }
            }
            path
            main
            private
            verified
            fsk18
            projectName
            __typename
        }
    }
`;

export const PROFILEIMAGECOUNT_QUERY = gql`
    query ImagesCount($where: ImageWhereInput) {
        findManyImageCount(where: $where)
    }
`;

export const PROFILE_UPDATE_MUTATION = gql`
    mutation updateOneProfile($where: ProfileWhereUniqueInput!, $data: ProfileUpdateInput!) {
        updateOneProfile(where: $where, data: $data) {
            ... on InputErrors {
                errors {
                    errors
                    fieldName
                }
            }
            ... on Profile {
                profileId
            }
        }
    }
`;

export const VERIFY_USER_MUTATION = gql`
    mutation verifyOneUser($where: UserWhereUniqueInput!, $data: UserVerifyInput!) {
        verifyOneUser(where: $where, data: $data) {
            userId
        }
    }
`;

export const USER_UPDATE_MUTATION = gql`
    mutation updateOneUser(
        $withTransactions: Boolean = false
        $withSocials: Boolean = true
        $withUserPremiumStatus: Boolean = false
        $where: UserWhereUniqueInput!
        $data: UserUpdateInput!
    ) {
        updateOneUser(where: $where, data: $data) {
            userId
            email
            lastLogin
            role
            username
            updatedAt
            createdAt
            createdIP
            sendTransactionalEmail
            sendMarketingEmail
            sendSMS
            emailValidated
            emailValidatedAt
            mobileNumber
            mobileNumberValidated
            mobileNumberValidatedAt
            emailBounce
            emailSuspendedUntil
            conditionAcceptedAt
            client
            clientVersion
            c4fId
            hasPayed
            verified
            unModerated
            ... @include(if: $withTransactions) {
                transactionCount
            }
            ... @include(if: $withUserPremiumStatus) {
                userPremiumStatus {
                    profilePremiumStatusId
                    status
                    validTo
                }
            }
            coins
            bonus
            latitude
            longitude
            ... @include(if: $withSocials) {
                socials {
                    providerName
                    socialId
                    providerId
                    registerDomain
                }
            }
        }
    }
`;
export const MODPROFILE_UPDATE_MUTATION = gql`
    mutation updateOneModeratedProfile($where: ModeratedProfileProjectWhereUniqueInput!, $data: ModeratedProfileUpdateInput!) {
        updateOneModeratedProfile(where: $where, data: $data) {
            moderatedProfileId
        }
    }
`;

export const USER_UNBIND_SOCIAL_MUTATION = gql`
    mutation unbindSocial($where: SocialWhereUniqueInput!) {
        unbindSocial(where: $where) {
            socialId
        }
    }
`;

export const MATCHPROFILE_CREATE_MUTATION = gql`
    mutation createMatchProfile($data: MatchProfileCreateInput!) {
        createMatchProfile(data: $data) {
            ... on Error {
                errorCode
            }
            ... on CreateMatchProfileResultSuccess {
                success
            }
        }
    }
`;

export const FIND_ONE_USER_QUERY = gql`
    ${USER_FRAGMENT}
    ${PROFILE_FRGAMENT}
    query findOneUser($where: UserWhereUniqueInput!, $withProfile: Boolean = false) {
        findOneUser(where: $where) {
            ...UserFragment
            ... @include(if: $withProfile) {
                profile {
                    ...ProfileFragment
                }
            }
        }
    }
`;

export const FIND_ONE_PROFILE_QUERY = gql`
    ${PROFILE_FRGAMENT}
    ${USER_FRAGMENT}
    ${MODERATED_PROFILE_FRAGMENT}
    ${PROFILE_INTERESTS_FRAGMENT}
    ${PROFILE_FLAGS_FRAGMENT}
    query findOneProfile(
        $where: ProfileWhereUniqueInput!
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withTransactions: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $withAvatar: Boolean = false
    ) {
        findOneProfile(where: $where) {
            ...ProfileFragment
            ... @include(if: $withAvatar) {
                createdAt
            }
            theme {
                name
                label
                images {
                    path
                }
            }
            ... @include(if: $withProfileFlags) {
                ...ProfileFlagsFragment
            }
            ... @include(if: $withImages) {
                images {
                    imageId
                    path
                    main
                    private
                    fsk18
                    projectName
                    verified
                }
            }
            ... @include(if: $withProfileInterests) {
                ...ProfileInterestsFragment
            }
            user {
                ...UserFragment
                ... @include(if: $withTransactions) {
                    transactionCount
                }
                ... @include(if: $withUserPremiumStatus) {
                    userPremiumStatus {
                        profilePremiumStatusId
                        status
                        validTo
                    }
                }
            }
            moderatedProfile {
                ...ModeratedProfileFragment
            }
        }
    }
`;

export const FIND_ONE_PROFILE_FEATURE_QUERY = gql`
    ${PROFILE_FRGAMENT}
    ${USER_FRAGMENT}
    ${MODERATED_PROFILE_FRAGMENT}
    ${PROFILE_INTERESTS_FRAGMENT}
    ${PROFILE_FLAGS_FRAGMENT}
    query findOneProfileFeatures(
        $where: ProfileWhereUniqueInput!
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withTransactions: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $withAvatar: Boolean = false
    ) {
        findOneProfile(where: $where) {
            ...ProfileFragment
            defaultText
            profileTextId
            theme {
                name
                label
                images {
                    path
                }
            }
            ... @include(if: $withAvatar) {
                avatar {
                    path
                    avatarId
                    name
                    gender
                    translations {
                        translation
                    }
                }
            }
            ... @include(if: $withProfileFlags) {
                ...ProfileFlagsFragment
            }
            ... @include(if: $withImages) {
                images {
                    imageId
                    path
                    main
                    private
                    fsk18
                    projectName
                    verified
                }
            }
            ... @include(if: $withProfileInterests) {
                ...ProfileInterestsFragment
            }
            user {
                ...UserFragment
                ... @include(if: $withTransactions) {
                    transactionCount
                }
                ... @include(if: $withUserPremiumStatus) {
                    userPremiumStatus {
                        profilePremiumStatusId
                        status
                        validTo
                    }
                }
            }
            moderatedProfile {
                ...ModeratedProfileFragment
            }
        }
    }
`;

export const FIND_ONE_PROFILE_HAZELZ_QUERY = gql`
    ${PROFILE_FRGAMENT}
    ${USER_FRAGMENT}
    ${MODERATED_PROFILE_FRAGMENT}
    ${PROFILE_INTERESTS_FRAGMENT}
    ${PROFILE_FLAGS_FRAGMENT}
    query findOneProfileHazelz(
        $where: ProfileWhereUniqueInput!
        $withImages: Boolean = false
        $withProfileInterests: Boolean = false
        $withProfileFlags: Boolean = false
        $withTransactions: Boolean = false
        $withUserPremiumStatus: Boolean = false
        $withAvatar: Boolean = false
    ) {
        findOneProfile(where: $where) {
            ...ProfileFragment
            theme {
                name
                label
                images {
                    path
                }
            }
            ... @include(if: $withAvatar) {
                avatar {
                    path
                    avatarId
                    name
                    gender
                    translations {
                        translation
                    }
                }
            }
            ... @include(if: $withProfileFlags) {
                ...ProfileFlagsFragment
            }
            ... @include(if: $withImages) {
                images {
                    imageId
                    path
                    main
                    private
                    fsk18
                    projectName
                    verified
                }
            }
            ... @include(if: $withProfileInterests) {
                ...ProfileInterestsFragment
            }
            user {
                ...UserFragment
                ... @include(if: $withTransactions) {
                    transactionCount
                }
                ... @include(if: $withUserPremiumStatus) {
                    userPremiumStatus {
                        profilePremiumStatusId
                        status
                        validTo
                    }
                }
            }
            moderatedProfile {
                ...ModeratedProfileFragment
            }
        }
    }
`;

export const PICTURE_QUERY = gql`
    query Pictures($where: PictureWhereInput, $orderBy: [PictureOrderByInput!], $cursor: PictureWhereUniqueInput, $skip: Int, $take: Int) {
        findManyPicture(where: $where, orderBy: $orderBy, cursor: $cursor, skip: $skip, take: $take) {
            imageId: pictureId
            profile: owner {
                profileName
                profileId
                images(where: { main: { equals: true } }) {
                    path
                }
                moderatedProfile {
                    moderatedProfileId
                }
            }
            path
            ownerId
            verified
            fsk18
            projectName
        }
    }
`;

export const PICTURE_COUNT_QUERY = gql`
    query PicturesCount($where: PictureWhereInput) {
        findManyPictureCount(where: $where)
    }
`;

export const PICTURE_UPDATE_MUTATION = gql`
    mutation updateManyPicture($data: [PictureUpdateManyInput!]!) {
        updateManyPicture(data: $data) {
            count
        }
    }
`;

export const EXPORT_USER_QUERY = gql`
    query exportManyUser($where: UserWhereInput) {
        exportManyUser(where: $where) {
            status
            path
        }
    }
`;
