import { gql } from '@apollo/client';

export const OFFER_FRAGMENT = gql`
    fragment OfferFragment on Offer {
        offerId
        name
        cost
        fee
        coins
        currency
        active
        bonus
        limit
        payed
        projectName
        extra
        profileStatus
        periodUnit
        statusPeriod
    }
`;

export const PROVIDER_QUERY = gql`
    ${OFFER_FRAGMENT}
    query findManyProvider {
        findManyProvider(orderBy: { active: desc }) {
            providerId
            projectName
            logo
            name
            title
            active
            description
            hidden
            currency
            offers(orderBy: { cost: asc }) {
                ...OfferFragment
            }
            settings {
                providerSettingId
                providerId
                name
                value
            }
        }
    }
`;

export const PROVIDER_MUTATION_UPDATE = gql`
    mutation updateOneProvider($data: ProviderUpdateInput!, $where: ProviderWhereUniqueInput!) {
        updateOneProvider(data: $data, where: $where) {
            providerId
        }
    }
`;

export const PROVIDER_MUTATION_CREATE = gql`
    mutation createOneProvider($data: ProviderCreateInput!) {
        createOneProvider(data: $data) {
            providerId
        }
    }
`;

export const PROVIDERSETTING_MUTATION_CREATE = gql`
    mutation createOneProviderSetting($data: ProviderSettingCreateInput!) {
        createOneProviderSetting(data: $data) {
            providerSettingId
        }
    }
`;

export const PROVIDERSETTING_MUTATION_UPDATE = gql`
    mutation updateOneProviderSetting($data: ProviderSettingUpdateInput!, $where: ProviderSettingWhereUniqueInput!) {
        updateOneProviderSetting(data: $data, where: $where) {
            providerSettingId
        }
    }
`;

export const OFFER_MUTATION_CREATE = gql`
    ${OFFER_FRAGMENT}
    mutation createOneOffer($data: OfferCreateInput!) {
        createOneOffer(data: $data) {
            ...OfferFragment
        }
    }
`;
export const OFFER_MUTATION_DELETE = gql`
    mutation deleteOneOffer($where: OfferWhereUniqueInput!) {
        deleteOneOffer(where: $where) {
            name
            cost
            providerName
        }
    }
`;
export const OFFER_MUTATION_UPDATE = gql`
    ${OFFER_FRAGMENT}
    mutation updateOneOffer($where: OfferWhereUniqueInput!, $data: OfferUpdateInput!) {
        updateOneOffer(where: $where, data: $data) {
            ...OfferFragment
        }
    }
`;

export const PROJECT_QUERY = gql`
    query findManyProject {
        findManyProject {
            name
        }
    }
`;

export const SALES_QUERY = gql`
    query findManyTransactionGroupBy($where: TransactionWhereInputWithoutUser) {
        findManyTransactionGroupBy(where: $where, by: [providerName], orderBy: [{ providerName: asc }], _sum: { cost: true }) {
            _sum {
                cost
            }
            providerName
        }
    }
`;

export const COUPON_QUERY = gql`
    query findManyCoupon($where: CouponWhereInput, $take: Int = 10, $skip: Int = 0) {
        findManyCoupon(where: $where, take: $take, skip: $skip) {
            code
            coins
            couponId
            bonus
            validFrom
            validTo
            limit
            projectName
            userCouponCount
        }
    }
`;

export const COUPON_QUERY_COUNT = gql`
    query findManyCouponCount($where: CouponWhereInput) {
        findManyCouponCount(where: $where)
    }
`;

export const COUPON_MUTATION_CREATE = gql`
    mutation createOneCoupon($data: CouponCreateInput!) {
        createOneCoupon(data: $data) {
            couponId
        }
    }
`;
export const COUPON_MUTATION_UPDATE = gql`
    mutation updateOneCoupon($data: CouponUpdateInput!, $where: CouponWhereUniqueInput!) {
        updateOneCoupon(data: $data, where: $where) {
            couponId
        }
    }
`;

export const COUPON_USER_QUERY = gql`
    query findManyUserCoupon($where: UserCouponWhereInput!, $take: Int = 10, $skip: Int = 0) {
        findManyUserCoupon(where: $where, orderBy: { createdAt: desc }, take: $take, skip: $skip) {
            couponCode
            user {
                username
                profile {
                    profileName
                    birthDate
                    gender
                    profileId
                }
                hasPayed
                lastPayedAt
                transactionCount
            }
            createdAt
        }
    }
`;

export const COUPON_USER_QUERY_COUNT = gql`
    query findManyUserCouponCount($where: UserCouponWhereInput) {
        findManyUserCouponCount(where: $where)
    }
`;

export const TRANSACTION_BOOK = gql`
    mutation bookTransaction($where: TransactionWhereUniqueInput!) {
        bookTransaction(where: $where) {
            transactionId
        }
    }
`;

export const TRANSACTION_CREATE = gql`
    mutation createOneTransaction($data: TransactionCreateInput!) {
        createOneTransaction(data: $data) {
            transactionId
        }
    }
`;

export const OFFER_QUERY = gql`
    ${OFFER_FRAGMENT}
    query findManyOffer($where: OfferWhereInput) {
        findManyOffer(where: $where) {
            ...OfferFragment
        }
    }
`;
