import { gql } from '@apollo/client';

export const USER_LEGACY_QUERY = gql`
    query legacyUsers($where: LegacyUserWhereInput!, $skip: Int, $take: Int) {
        legacyUsers(where: $where, skip: $skip, take: $take) {
            username
            email
            userId
            projectName
            necomUserId
        }
    }
`;

export const USER_LEGACY_IMPORT_MUTATION = gql`
    mutation importLegacyUser($data: LegacyUserImportInput!) {
        importLegacyUser(data: $data) {
            username
            email
            userId
        }
    }
`;
